@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.home-top img {
  width: 100%;
  height: 90vh;
}

/* Youtube Section */
.home-video-section {
  overflow: hidden;
}
.youtube-section {
  border-radius: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 10rem 4rem;
  position: relative;
  margin: 3rem;
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.youtube-right p {
  font: normal bold 20px/35px "Bona Nova";
  width: 900px;
  color: white;
}

/* Home Bottom Cards */
.home-second-section {
  margin: 4rem 3rem;
}

.experts-div {
  padding: 2rem;
  background-color: #e7e7e7;
  text-align: center;
  margin-bottom: 4rem;
}

.expert-title {
  font: normal bold 25px/1 "Montserrat";
  margin-bottom: 2rem;
}

.expert-content {
  font: normal 20px/1 "Bona Nova";
  /* text-align: justify; */
}

/* Green Energy Section */
.green-energy-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 5rem;
}

.green-title {
  font: normal bold 22px/1 "Montserrat";
}

.green-content {
  width: 680px;
  font: normal 19px/28px "Bona Nova";
  text-align: justify;
}

.green-right img {
  width: 650px;
  height: 450px;
  border-radius: 25% 10%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Design Studio */
.design-studio-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.design-left img {
  width: 650px;
  height: 450px;
  border-radius: 10% 25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Certification Section */
.cert-main {
  margin: 2rem 0;
}

.cert-title {
  font: normal bold 25px/1 "Montserrat";
  margin: 5rem 0;
  text-align: center;
}

.cert-flex {
  display: flex;
  justify-content: space-evenly;
  padding: 4rem 2rem;
  background: linear-gradient(to right, #e9953b, #eebb8b);
}

.cert-card {
  width: 200px;
  height: 100px;
  padding: 15px;
  border-radius: 7px;
  background-color: white;
  transition: 0.4s all ease-in-out;
}

.cert-card img {
  width: 100%;
  height: 100px;
  border-radius: 17px;
}

.cert-card:hover {
  transform: scale(1.1);
}

/* Associates Main */
.associate-title {
  font: normal bold 25px/1 "Montserrat";
  text-align: center;
  margin: 2rem 0;
  margin-bottom: 5rem;
}

.associate-flex {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
  gap: 3rem;
  margin-bottom: 5rem;
}

.home-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid #e7e7e7;
  padding: 20px;
  width: 300px;
  transition: 0.5s all ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 1.4rem;
}

.home-div:hover {
  background-color: var(--primaryColor);
  color: white;
}

.home-icon {
  font-size: 25px;
}

.associate-sub-title {
  font: normal bold 20px/1 "Bona Nova";
}

/*Review*/
.review-main {
  margin-bottom: 5rem;
}
.review-title {
  font: normal bold 25px/1 "Montserrat";
  text-align: center;
  margin: 1rem;
}

.review-comments {
  margin: 0 10px;
  overflow: hidden;
}

/* Media Query */

@media (max-width: 1060px) {
  .green-energy-main {
    flex-direction: column-reverse;
  }

  .design-studio-main {
    flex-direction: column;
    align-items: center;
  }

  .cert-flex {
    display: grid;
    grid-template-columns: auto auto;
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .home-top img {
    width: 100%;
    height: auto;
  }

  .youtube-section,
  .home-second-section {
    margin: 0;
  }

  .youtube-right p {
    width: 100%;
  }

  .green-energy-main,
  .design-studio-main {
    width: 100%;
    gap: 0;
  }

  .green-right img {
    width: 100%;
    height: auto;
  }

  .green-left,
  .design-right,
  .design-left,
  .green-right {
    padding: 10px;
  }

  .green-content {
    width: 100%;
  }

  .design-left img {
    width: 100%;
    height: auto;
  }

  .associate-flex {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    gap: 0;
  }
}

@media (max-width: 630px) {
  .cert-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .youtube-section {
    margin: 2rem 0 0 0;
    padding: 2rem;
  }

  .experts-div {
    margin: 2rem 0;
    padding: 2rem 0;
  }

  .cert-title {
    margin: 0;
  }

  .green-energy-main {
    margin-bottom: 0;
  }

  .associate-title {
    margin-bottom: 2rem;
  }

  .associate-main {
    width: 100%;
  }

  .associate-flex {
    width: 100%;
    grid-template-columns: auto;
  }

  .home-div {
    width: 100%;
    padding: 20px 0;
  }
  .associate_container {
    width: 90%;
  }
}

/* Media Query End */
