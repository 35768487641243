@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.navbar-left img {
  width: 100px;
  height: 12vh;
  object-fit: cover;
}

.nav-title {
  font: normal bold 25px/1 "Bona Nova";
  margin: 0;
}

.navbar-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
}

.df {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Menu_Icon {
  padding-right: 2%;
  display: none;
}

.navbar-main {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #e7e7e7;
}

.nav-right {
  width: 100%;
}

.nav-right ul li {
  list-style: none;
  font: normal bold 18px/1 "Bona Nova";
}

.nav-right ul {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
}

.nav-links {
  text-decoration: none;
  color: black;
}
.nav-links:hover {
  color: var(--primaryColor);
}
.active {
  color: green;
}

/* Media Query */

@media (max-width: 1060px) {
  .nav-right ul {
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.596);
    padding: 20px;
    z-index: 999;
  }

  .nav-right ul li {
    padding: 10px;
  }

  .nav-links {
    color: white !important;
    z-index: 999;
  }

  .nav-right {
    width: 100%;
    position: absolute;
    top: 82%;
    display: none;
  }

  .ds_none {
    display: block;
    transition: all 2000ms ease-in-out;
    transition-duration: 2000ms;
    transition-delay: 200ms;
    z-index: 999;
  }

  .Menu_Icon {
    display: block;
  }
}

@media (max-width: 500px) {
  .navbar-left img {
    width: 70px;
    /* height: 60px; */
    object-fit: cover;
  }

  .nav-title {
    font: normal bold 20px/1 "Bona Nova";
    margin: 0;
  }
}
/* Media Query End */
