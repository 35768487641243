@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.product-top {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 3rem 0;
}

.product-title {
  font: normal bold 25px/1 "Montserrat";
}

.product-content {
  font: normal 18px/1 "Bona Nova";
}

/* Product Flex */
.product-card {
  width: 340px;
  height: 380px;
  padding: 7px;
  background-image: linear-gradient(to right, #000000, #434343);
  margin: 2rem 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.product-title-two {
  text-align: center;
  font: normal bold 27px/1 "Montserrat";
}

.product-link {
  text-decoration: none;
}

.product-card img {
  width: 100%;
  height: 315px;
  object-fit: cover;
}

.product-sub-title {
  font: normal bold 20px/1 "Bona Nova";
  letter-spacing: 1px;
  color: white;
  text-align: center;
}

.product-flex {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 5rem;
}

/* Media Query */

@media (max-width: 1060px) {
  .product-flex {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

@media (max-width: 400px) {
  .product-card {
    width: 300px;
    padding: 0;
  }
}

/* Media Query End */
