@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.footer-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-flex {
  display: flex;
  justify-content: space-evenly;
  padding: 4rem 0;
  background-color: rgb(39, 39, 39);
  color: white;
}

.footer-left {
  width: 450px;
}

.footer-icon {
  font-size: 30px;
}

.footer-title {
  font: normal bold 27px/1 "Bona Nova";
  margin-bottom: 2rem !important;
  margin: 0;
  letter-spacing: 1px;
}

.footer-nav-links {
  text-decoration: none;
  color: rgb(248, 246, 246);
}

.footer-address {
  font: normal bold 17px/1 "Bona Nova";
  letter-spacing: 0.5px;
}

.footer-content {
  font: normal bold 17px/30px "Bona Nova";
  letter-spacing: 0.5px;
  text-align: justify;
}

.footer-three ul li {
  list-style: none;
  font: normal bold 17px/30px "Bona Nova";
  letter-spacing: 0.5px;
}

/* Media Query */
@media (max-width: 1060px) {
  .footer-flex {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 3rem;
    padding: 0 5rem;
  }

  .footer-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .footer-div {
    justify-content: center !important;
    margin: auto;
  }

  .footer-title {
    text-align: center;
  }

  .footer-left {
    width: 100%;
  }
}

.footer-bottom-flex {
  background-color: #252627;
  padding: 1.5rem 0;
  color: white;
  border-top: 0.1px solid #e7e7ee;
}
.footer-btm-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-copyright {
  font: normal 20px/1 "Alegreya";
  text-align: center;
  margin: 0;
}
.footer-copyright span a {
  text-decoration: none;
  color: white;
  font: normal bold 20px/1 "Alegreya";
  padding-left: 5px;
  transition: 0.5s all ease;
}
.footer-copyright span a:hover {
  color: rgba(65, 65, 230, 0.8);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-flex {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .footer-flex {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    /* padding-left: 1rem; */
    /* padding-left: 2rem; */
  }
}

/* Media Query End */
