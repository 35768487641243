@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.process-title {
  font: normal bold 30px/1 "Montserrat";
  text-align: center;
  margin: 2em 0;
}

.process-card {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.process-card-left {
  width: 700px;
}

.process-card-content {
  font: normal 19px/30px "Bona Nova";
  /* width: 580px; */
  margin-bottom: 2.5rem;
  text-align: justify;
}

.process-card-title {
  font: normal bold 24px/1 "Montserrat";
}

.process-sub-title {
  font: normal bold 18px/1 "Montserrat";
}

.process-card-left ul li {
  font: normal 19px "Bona Nova";
  margin-bottom: 1rem;
  list-style: circle;
  /* width: 580px; */
}

/* Process Right */
.process-right-img {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 0.5rem;
}

.process-right-img img {
  width: 10rem;
  height: 150px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.image-title {
  margin: 10px 0;
  text-align: center;
  font: normal bold 15px/1 "Montserrat";
}

/* Second Section */
.process-card-two {
  /* flex-direction: row-reverse; */
  /* gap: 5rem; */
  margin-top: 3rem;
}

.card-list-div {
  margin-bottom: 40px;
}

/* InterDeccaan  */

/* Weaving */

.weaving-section {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.weaving-image-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.weaving-image-container img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Weaving End */

/* Looms */

.looms-section {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row-reverse;
}

.loom-content,
.weaving-content,
.dyeing-content {
  font: normal 19px/30px "Bona Nova";
}

.looms-section ul li {
  font: normal 19px "Bona Nova";
  margin-bottom: 1rem;
  list-style: circle;
  width: 580px;
}

.loom-image-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.loom-image-container img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Looms End */

/* Dyeing */

.dyeing-section {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.dyeing-image-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dyeing-image-container img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Dyeing End */

/* Media Query */

@media (max-width: 1060px) {
  .weaving-section {
    flex-direction: column-reverse;
    align-items: center;
  }

  .weaving-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .looms-section {
    flex-direction: column-reverse;
    align-items: center;
  }

  .loom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .process-main {
    margin: 0 2rem;
  }

  .process-card {
    flex-direction: column;
  }

  .weaving-content,
  .loom-content {
    width: 100%;
    background: red;
  }

  .process-card-left {
    width: auto;
  }
  .process-right-img {
    /* overflow: hidden; */
    width: auto;
  }
}

/* Media Query End */

/* InterDeccaan End */
