@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

/* about-testimonials  */

.about-testimonials {
  width: 96%;
  display: flex;
  align-items: center;
  /* background-color: #e7e7e7;
  border-radius: 20px;     */
  margin: auto;
  padding: 20px;
  isolation: isolate;
  position: relative;
}

.about-testimonials::after {
  content: "";
  background-color: #e7e7e7;
  z-index: -1;
  inset: 0;
  opacity: 0.7;
  border-radius: 20px;
  position: absolute;
}

.about-testimonials ul {
  padding: 0px 20px 20px 20px;
}

.about-testimonials ul li {
  list-style-type: none;
  font: normal bold 20px/45px "Montserrat";
}

.about-container-image img {
  width: 700px;
  border-radius: 20px;
  box-shadow: 5px 5px 10px 5px #888;
}

/* about-testimonials End */

/* Main Background Images */

.about-main {
  /* background: url(/src/Assets/About/BackGround.avif); */
  background-size: cover;
  background-attachment: fixed;
  isolation: isolate;
  position: relative;
  padding: 10px 0;
  z-index: -1;
}

.about-main::after {
  inset: 0;
  z-index: -1;
  content: "";
  opacity: 0.5;
  /* background: linear-gradient(285deg, #00000097, black); */
  position: absolute;
}

/* Main Background Images End */

.director-main-title {
  padding: 1rem 0;
  color: black;
  text-align: center;
  font: normal bold 28px/1 "Montserrat";
}

.director-flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 3rem 0;
}

.director-flex img {
  transform: scaleX(-1);
}

.director-flex-two {
  display: flex;
  flex-direction: row-reverse;
}

.director-flex-two img {
  transform: scaleX(-1);
}

.director-left {
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  width: 380px;
  height: 100px;
  padding: 7px;
  color: white;
  background-image: linear-gradient(to right, #000000, #434343);
}

.director-name {
  font: normal bold 25px/1 "Bona Nova";
  margin-bottom: 15px;
}

.director-content {
  font: normal bold 20px/1 "Bona Nova";
  margin: 0;
}

.director-right img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  padding: 7px;
  background-image: linear-gradient(to right, #000000, #434343);
}

/* Media Query */

@media (max-width: 1500px) {
  .about-testimonials ul li {
    list-style-type: none;
    font: normal bold 18px/30px Montserrat;
  }

  .about-container-image img {
    width: 500px;
    height: 400px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 5px #888;
  }
}

@media (max-width: 1060px) {
  .about-testimonials {
    flex-direction: column-reverse;
  }

  .about-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .about-testimonials {
    width: 96%;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .about-container-image {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .about-container-image img {
    width: 80%;
    height: auto;
  }

  .director-flex {
    flex-direction: column-reverse;
    align-items: center;
  }
}

/* Media Query End */
