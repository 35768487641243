@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.contact-title {
  font: normal bold 35px/1 "Montserrat";
  margin: 0;
  text-align: center;
}

.contact-content {
  font: normal bold 20px/1 "Bona Nova";
  text-align: center;
  margin-bottom: 45px;
  margin-top: 30px;
}

.contact-flex {
  display: flex;
  justify-content: center;
  margin: 4rem 15rem;
  padding: 4rem 0;
  border: 1px solid #e7e7e7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact-input input {
  width: 450px;
  padding: 15px;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid #e7e7ee;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.contact-input textarea {
  width: 450px;
  padding: 15px;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid #e7e7ee;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

::placeholder {
  font: normal 15px/1 "Bona Nova";
}

.contact-btn button {
  border: none;
  width: 200px;
  padding: 12px;
  background-color: black;
  color: white;
  font: normal bold 18px/1 "Bona Nova";
  margin-top: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-btn {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.contact-form {
  display: flex;
  justify-content: center;
}

.contact-main {
  height: 100%;
  position: relative;
}

.contact-bg {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.contact-bg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.contact-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

@media (max-width: 1000px) {
  .contact-flex {
    margin: 0;
    z-index: -1 !important;
  }
}

@media (max-width: 500px) {
  .contact-input input {
    width: 250px;
    border-radius: 10px;
  }
  .contact-input textarea {
    width: 250px;
    border-radius: 10px;
  }
}
