.comment {
  padding: var(--mantine-spacing-lg) var(--mantine-spacing-xl);
  /* height: 10rem; */
  /* width: 30rem; */
  margin: 10px 0;
}

.body {
  padding-left: rem(54px);
  padding-top: var(--mantine-spacing-sm);
  font-size: var(--mantine-font-size-sm);
}

.content {
  & > p:last-child {
    margin-bottom: 0;
  }
}
