@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,200;0,400;0,500;1,400&display=swap");

.product-sub-main {
  background-image: linear-gradient(to right, #000000, #434343);
  padding: 2rem 0;
  margin: 0;
}

.product-sub-main-title {
  text-align: center;
  margin-bottom: 3rem;
  font: normal bold 35px/1 "Bona Nova";
  color: white;
}

.product-sub-img-flex img {
  width: 350px;
  height: 300px;
  border-radius: 10px;
  padding: 10px;
  background-color: #e7e7e7;
}

.product-sub-img-flex {
  display: flex;
  gap: 2rem;
  justify-content: center;
}


/* Media Query */

@media (max-width:1060px) {
  .product-sub-img-flex {
    flex-direction: column;
    position: relative;
    align-items: center;
  }
}

@media (max-width:400px) {
  .product-sub-img-flex img {
    width: 95%;
    height: 45vh;
    padding: 0;
  }
}


@media (max-width:300px){
  .product-sub-img-flex img {
    width: 95%;
    height: 30vh;
    padding: 0;
  }
}

/* Media Query End */